/* eslint-disable react/prop-types */
import React from 'react'
import DrawerMenu from 'rc-drawer'
import SiderMenu from './SiderMenu'

import 'rc-drawer/assets/index.css'
import './index.less'

const SiderMenuWrapper = (props) => {
  const { isMobile, collapsed } = props

  return isMobile ? (
    <DrawerMenu
      getContainer={null}
      level={null}
      handlechild={<i className="drawer-handle-icon" />}
      onHandleClick={() => {
        props.onCollapse(!collapsed)
      }}
      open={!collapsed}
      onClose={() => {
        props.onCollapse(true)
      }}
    >
      <SiderMenu {...props} collapsed={isMobile ? false : collapsed} />
    </DrawerMenu>
  ) : (
    <SiderMenu {...props} />
  )
}

export default SiderMenuWrapper
