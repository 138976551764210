/*
 * @Author: your name
 * @Date: 2022-02-21 17:30:04
 * @LastEditTime: 2022-04-22 13:40:36
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\concent\counter\index.js
 */
// import util from '../../utils/util'

// const delay = (ms = 2000) => new Promise((resolve) => setTimeout(resolve, ms))
export default async function () {}
