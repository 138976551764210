/*
 * @Author: your name
 * @Date: 2020-11-20 09:40:39
 * @LastEditTime: 2022-09-23 14:31:36
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \oaContract\src\utils\urls.js
 */
const env =
  process.env.BUILD_ENV === 'localhost' ? 'demo' : process.env.BUILD_ENV

let urls: any

const protocol = window.location.protocol === 'https:' ? 'https:' : 'http:'
console.log('env', env)
if (env === 'dev') {
  // dev
  urls = {
    API: protocol + '//costcontrolweb.dev.52koala.com.cn/costcontrolapi',
    LOGINAPI: protocol + '//costcontrolweb.dev.52koala.com.cn/loginservice',
    LOGIN: protocol + '//login.dev.52koala.com.cn',
    BXAPI: protocol + '//costcontrolweb.dev.52koala.com.cn/reimburseapi',
    // PERSONAPI:
    PERSONAPI: protocol + '//costcontrolweb.dev.52koala.com.cn/personnelapi',
    WORK_URL: protocol + '//workbenchpage.dev.52koala.com.cn',
  }
} else if (env === 'demo') {
  // demo
  urls = {
    API: protocol + '//apigateway.demo.52koala.com.cn/koalaCostControlApi',
    LOGINAPI: protocol + '//costcontrolweb.demo.52koala.com.cn/loginservice',
    LOGIN: '//login.demo.52koala.com.cn',
    BXAPI: protocol + '//apigateway.demo.52koala.com.cn/koalaReimburseApi',
    PERSONAPI: protocol + '//apigateway.demo.52koala.com.cn/koalaPersonnelApi',
    REIMBURSEWEB: protocol + '//reimburseweb.demo.52koala.com.cn',
    APIGATEWAY: '//apigateway.demo.52koala.com.cn',
    WORK_URL: protocol + '//workbenchpage.demo.52koala.com.cn',
  }
} else if (env === 'testing') {
  // testing
  urls = {
    API: protocol + '//apigateway.52koala.cn/koalaCostControlApi',
    LOGINAPI: protocol + '//costcontrolweb.52koala.cn/loginservice',
    LOGIN: protocol + '//login.52koala.cn',
    BXAPI: protocol + '//apigateway.52koala.cn/koalaReimburseApi',
    PERSONAPI: protocol + '//apigateway.52koala.cn/koalaPersonnelApi',
    REIMBURSEWEB: protocol + '//reimburseweb.52koala.cn',
    APIGATEWAY: '//apigateway.52koala.cn',
    WORK_URL: protocol + '//workbenchpage.52koala.cn',
  }
} else {
  urls = {
    API: protocol + '/costcontrolapi',
    LOGINAPI: protocol + '/loginservice',
    LOGIN: protocol + '//login.demo.52koala.com.cn',
    BXAPI: protocol + '/reimburseapi',
    PERSONAPI: protocol + '/personnelapi',
    REIMBURSEWEB: protocol + '//reimburseweb.demo.52koala.com.cn',
    APIGATEWAY: '//apigateway.demo.52koala.com.cn',
    WORK_URL: protocol + '//workbenchpage.demo.52koala.com.cn',
  }
}

export default urls
