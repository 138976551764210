/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { SettingOutlined } from '@ant-design/icons'

import './SiderMenu.less'

const { Sider } = Layout
const { SubMenu } = Menu

export default class SiderMenu extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      openKeys: props.path,
      selectedKeys: props.path,
      asideNavsDefault: [],
      privilegeTree: []
    }
  }

  componentDidMount() {}

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const oldPath = this.props.path.join('')
    const newPath = nextProps.path.join('')

    const oldCollapsed = this.props.collapsed
    const newCollapsed = nextProps.collapsed

    if (oldPath !== newPath || oldCollapsed !== newCollapsed) {
      this.setState({
        // openKeys: !nextProps.collapsed ? nextProps.path : [],
        selectedKeys: nextProps.path
      })
    }
  }

  // submenu keys of first level
  rootSubmenuKeys = ['/home']

  onOpenChange = (openKeys) => {
    const latestOpenKey = openKeys.find(
      (key) => this.state.openKeys.indexOf(key) === -1
    )

    if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      this.setState({ openKeys })
    } else {
      this.setState({
        openKeys: latestOpenKey ? [latestOpenKey] : []
      })
    }
  }

  // icons
  iconHandler(name) {
    const iconConfig = [
      {
        moduleId: 'CCSYS_M_00001',
        icon: <SettingOutlined />
      },
      {
        moduleId: 'HTWEB_M_00006',
        icon: <SettingOutlined />
      },
      {
        moduleId: 'CCSYS_M_00002',
        icon: (
          <img
            style={{
              width: '16px',
              marginRight: '10px',
              verticalAlign: 'text-bottom'
            }}
            src={require('../../assets/budget_icon.png')}
          ></img>
        )
      }
      // {
      //   moduleId: 'CRMSYSTEM_M_00002',
      //   icon: <SettingOutlined />
      // },
      // {
      //   moduleId: 'CRMSYSTEM_M_00003',
      //   icon: <SettingOutlined />
      // }
    ]
    let result = ''

    iconConfig.forEach((item) => {
      if (item.moduleId === name) {
        result = item.icon
      }
    })

    return result
  }

  // menu
  renderMenu(currentUser) {
    const navs = []
    const privilegeTree = currentUser.privilegeTree

    if (privilegeTree && privilegeTree.length > 0) {
      privilegeTree.forEach((nav) => {
        if (nav.levelType === 2) {
          const i = {}

          i.text = nav.moduleName
          i.to = nav.moduleUrl
          i.id = nav.moduleId
          i.icon = this.iconHandler(nav.moduleId)
          i.children = []
          i.parentId = nav.parentId

          navs.push(i)
        }
      })

      privilegeTree.forEach((nav) => {
        if (nav.levelType === 3) {
          const i = {}
          const j = navs.findIndex((elem) => {
            return elem.id === nav.parentId
          })

          i.text = nav.moduleName
          i.to = nav.moduleUrl
          i.id = nav.moduleId
          i.parentId = nav.parentId

          navs[j].children.push(i)
        }
      })
    }

    return this.state.asideNavsDefault.concat(navs)
  }

  render() {
    const { collapsed, onCollapse, currentUser } = this.props
    const { openKeys, selectedKeys } = this.state

    // Don't show popup menu when it is been collapsed
    const menuProps = collapsed ? {} : { openKeys }

    const asideNavsDefault = this.renderMenu(currentUser)

    return (
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        breakpoint="lg"
        onCollapse={onCollapse}
      >
        {collapsed ? <div className="logo-mobile" /> : <div className="logo" />}
        <Menu
          theme="dark"
          mode="inline"
          {...menuProps}
          selectedKeys={selectedKeys}
          onOpenChange={this.onOpenChange}
        >
          {/* <SubMenu
            key="/home"
            title={
              <span>
                <DashboardOutlined />
                <span>Dashboard</span>
              </span>
            }
          >
            <Menu.Item key="/home">
              <Link to="/home">
                <span>首页</span>
              </Link>
            </Menu.Item>
          </SubMenu> */}
          {asideNavsDefault &&
            asideNavsDefault.length > 0 &&
            asideNavsDefault.map((nav) => {
              if (nav.children && nav.children.length > 0) {
                return (
                  <SubMenu
                    key={nav.to}
                    title={
                      <span>
                        {nav.icon ? nav.icon : null}
                        <span className="ice-menu-collapse-hide">
                          {nav.text}
                        </span>
                      </span>
                    }
                  >
                    {nav.children.map((item) => {
                      const linkProps = {}
                      linkProps.to = item.to

                      return (
                        <Menu.Item key={item.to}>
                          <Link {...linkProps}>{item.text}</Link>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                )
              }

              const linkProps = {}
              linkProps.to = nav.to

              return (
                <Menu.Item key={nav.to}>
                  <Link {...linkProps}>
                    <span>
                      {nav.icon ? nav.icon : null}
                      <span className="ice-menu-collapse-hide">{nav.text}</span>
                    </span>
                  </Link>
                </Menu.Item>
              )
            })}
        </Menu>
      </Sider>
    )
  }
}
