import KoalaRequest, { KoalaRequestEncryptUtils } from '@koala/request'
import { Modal } from 'antd'
import { LoginServiceAPI } from '@ehi/types'
import urls from '../utils/urls'

const buildEnv =
  process.env.BUILD_ENV === 'localhost' ? 'demo' : process.env.BUILD_ENV
const isTestingEnv = () => {
  // 测试环境
  return !buildEnv || buildEnv === 'dev' || buildEnv === 'demo'
}
// 初始化解密类
KoalaRequest.encryptUtils = new KoalaRequestEncryptUtils({
  env: buildEnv,
})
const debug = isTestingEnv()

const jumpToLogin = () =>
  (window.location.href = `${urls.LOGIN}?returnUrl=${window.location.href}`)

const handleLogin = (message?: string) => {
  Modal.destroyAll()
  Modal.info({
    title: '账号异常',
    content: message || '登录状态已过期，请重新登录。',
    okText: '回到登录页',
    onOk: jumpToLogin,
  })
}

// login站点请求
export const loginRequest = new KoalaRequest({
  encryptType: 'dynamic',
  versionCode: 6040,
  // 配置axios
  axiosConfig: {
    // 配置login站点请求路径
    baseURL: `${urls.APIGATEWAY}/sso-login`,
  },
  // 401未授权状态码逻辑修改
  unauthHandler: handleLogin,
  /* 开启响应参数解密 */
  decryptResponseParams: true,
  /** 开启请求参数加密 */
  encryptRequestParams: true,
  debug,
})

export const apiRequest = new KoalaRequest({
  encryptType: 'static',
  signatureKey: '3c6fa384648ffd5cf229ddf5ac82c480',
  // 配置axios
  axiosConfig: {
    // 配置login站点请求路径
    baseURL: '',
  },
  // 401未授权状态码逻辑修改
  unauthHandler: handleLogin,
  /* 开启响应参数解密 */
  decryptResponseParams: true,
  // /** 开启请求参数加密 */
  encryptRequestParams: true,
  encryptKeyMode: 'base64',
  encryptKey: 'N2hmXiRIZCpnM0AjIWZkNA',
  encryptIv: 'YTFyZzM1RGV3NDdmNGZmaw==',
  debug,
})

// 上传请求
const uploadRequest = new KoalaRequest({
  encryptType: 'static',
  signatureKey: '3c6fa384648ffd5cf229ddf5ac82c480',
  // 配置axios
  axiosConfig: {
    // 配置login站点请求路径
    baseURL: `${urls.APIGATEWAY + '/file-service'}`,
    extraHeaders: { enc: true },
  },
  // 401未授权状态码逻辑修改
  unauthHandler: handleLogin,
  /* 开启响应参数解密 */
  decryptResponseParams: true,
  /** 开启请求参数加密 */
  encryptRequestParams: true,
  encryptKeyMode: 'base64',
  encryptKey: 'N2hmXiRIZCpnM0AjIWZkNA',
  encryptIv: 'YTFyZzM1RGV3NDdmNGZmaw==',
  debug,
})

export default function getAuthUserInfo(moduleId?: string) {
  // 权限系统中当前项目对应的系统编码
  const systemCode = 'CCSYS'
  return loginRequest.get<
    LoginServiceAPI.LoginUserInfoResult,
    LoginServiceAPI.LoginUserInfoParams
  >('/Auth/UserInfo', {
    systemCode,
    moduleId,
  })
}

// 网关上传接口
export function uploadFileApi(params: any, data: any) {
  return uploadRequest.post('/file/upload', data, params)
}
/**
 * 根据ModuleId获取权限列表
 * @param {string} moduleId
 */
export async function getBtnAuthListByModuleId(moduleId: string) {
  const { privilegeTree } = await getAuthUserInfo(moduleId)
  return privilegeTree
}
