// import { getTimeForApi } from './../routes/ContractApply/Api/index';
import moment from 'moment'
import util from '../utils/util'
import getAuthUserInfo from './gateWayRequest'

/**
 * @description 获取用户userCode
 * @author yaoyihao
 * @date 31/08/2022
 * @export
 * @return {*} string
 */
export function getUserId() {
  return util.getStorage('userInfo')
    ? util.getStorage('userInfo')?.idNumber
    : null
}
/**
 * @description 获取用户info
 * @author yaoyihao
 * @date 31/08/2022
 * @export
 * @return {*} {}
 */
export function getUserInfo() {
  return util.getStorage('userInfo') ?? null
}
/**
 * @description 获取页面权限
 * @param
 * @returns  true
 */
export const getUserLimitList = (moduleId) => {
  return new Promise((resolve, reject) => {
    getAuthUserInfo(moduleId)
      .then((res) => {
        const result = res
        resolve(result.privilegeTree)
      })
      .catch((err) => {
        console.log(err)
        reject(err)
      })
  })
}
// const env = process.env.BUILD_ENV
const env = process.env.BUILD_ENV

/**
 * @description 检查权限id
 * @param (id:string,limitList:[])
 * @returns boolean
 */
export const checkAuthority = (id, limitList) => {
  // const maps = new Map()
  return env === 'localhost1'
    ? true
    : limitList && limitList.some((_) => _ && _?.moduleId === id)
}

/**
 * @description 获取接口入参格式的时间
 * @author yaoyihao
 * @date 31/08/2022
 * @export
 * @return {*} string
 */
export function getTimeForApi(time: string) {
  return time ? moment(time).format('YYYY-MM-DD HH:mm') : null
}

/**
 * @description  获取url值
 * @param {name:属性名string, str:地址string}
 * @returns any
 */
export function getUrlParams(name, str, isNotNeedDecode = false) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = str.substr(1).match(reg)
  if (r != null) return isNotNeedDecode ? r[2] : decodeURIComponent(r[2])
  return null
}

/**
 * @description 获取接口入参格式的时间
 * @author yaoyihao
 * @date 31/08/2022
 * @export
 * @return {*} string
 */
export function getStartTimeForApi(time: string) {
  return time ? moment(time).format('YYYY-MM-DD 00:00:00') : null
}

/**
 * @description 获取接口入参格式的时间
 * @author yaoyihao
 * @date 31/08/2022
 * @export
 * @return {*} string
 */
export function getEndTimeForApi(time: string) {
  return time ? moment(time).format('YYYY-MM-DD 23:59:59') : null
}

/**
 * @param {*} status 当前状态
 * @param  {...any} choose 允许显示的状态数组
 */
export const judgeStatusBtn = (
  status: number | string,
  ...choose: number[] | string[]
) => {
  const result = choose.some((item) => item === status)
  return result
}
