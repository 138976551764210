/* eslint-disable */
/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
/**
 * CryptoJS core components.
 */
let CryptoJS = (function(Math) {
  /**
   * CryptoJS namespace.
   */
  let C = {}

  /**
   * Library namespace.
   */
  let CLib = (C.lib = {})

  /**
   * Base object for prototypal inheritance.
   */
  let Base = (CLib.Base = (function() {
    function F() {}

    return {
      /**
       * Creates a new object that inherits from this object.
       *
       * @param {Object} overrides Properties to copy into the new object.
       *
       * @returns {Object} The new object.
       *
       * @static
       *
       * @example
       *
       *     let MyType = CryptoJS.lib.Base.extend({
       *         field: 'value',
       *
       *         method: function () {
       *         }
       *     });
       */

      extend: function(overrides) {
        // Spawn
        F.prototype = this
        let subtype = new F()

        // Augment
        if (overrides) {
          subtype.mixIn(overrides)
        }

        // Create default Initializer
        if (!subtype.hasOwnProperty('Init')) {
          subtype.Init = function() {
            subtype.$super.Init.apply(this, arguments)
          }
        }

        // Initializer's prototype is the subtype object
        subtype.Init.prototype = subtype

        // Reference supertype
        subtype.$super = this

        return subtype
      },

      /**
       * Extends this object and runs the Init method.
       * Arguments to create() will be passed to Init().
       *
       * @returns {Object} The new object.
       *
       * @static
       *
       * @example
       *
       *     let instance = MyType.create();
       */

      create: function() {
        let instance = this.extend()
        instance.Init.apply(instance, arguments)

        return instance
      },

      /**
       * Initializes a newly created object.
       * Override this method to add some logic when your objects are created.
       *
       * @example
       *
       *     let MyType = CryptoJS.lib.Base.extend({
       *         Init: function () {
       *             // ...
       *         }
       *     });
       */

      Init: function() {},

      /**
       * Copies properties into this object.
       *
       * @param {Object} properties The properties to mix in.
       *
       * @example
       *
       *     MyType.mixIn({
       *         field: 'value'
       *     });
       */

      mixIn: function(properties) {
        for (let propertyName in properties) {
          if (properties.hasOwnProperty(propertyName)) {
            this[propertyName] = properties[propertyName]
          }
        }

        // IE won't copy toString using the loop above
        if (properties.hasOwnProperty('toString')) {
          this.toString = properties.toString
        }
      },

      /**
       * Creates a copy of this object.
       *
       * @returns {Object} The clone.
       *
       * @example
       *
       *     let clone = instance.clone();
       */

      clone: function() {
        return this.Init.prototype.extend(this)
      }
    }
  })())

  /**
   * An array of 32-bit words.
   *
   * @property {Array} words The array of 32-bit words.
   * @property {number} sigBytes The number of significant bytes in this word array.
   */
  let WordArray = (CLib.WordArray = Base.extend({
    /**
     * Initializes a newly created word array.
     *
     * @param {Array} words (Optional) An array of 32-bit words.
     * @param {number} sigBytes (Optional) The number of significant bytes in the words.
     *
     * @example
     *
     *     let wordArray = CryptoJS.lib.WordArray.create();
     *     let wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607]);
     *     let wordArray = CryptoJS.lib.WordArray.create([0x00010203, 0x04050607], 6);
     */

    Init: function(words, sigBytes) {
      words = this.words = words || []

      if (sigBytes !== undefined) {
        this.sigBytes = sigBytes
      } else {
        this.sigBytes = words.length * 4
      }
    },

    /**
     * Converts this word array to a string.
     *
     * @param {string} encoder Encoder -- (Optional) The encoding strategy to use. Default: CryptoJS.enc.Hex
     *
     * @returns {string} The stringified word array.
     *
     * @example
     *
     *     let string = wordArray + '';
     *     let string = wordArray.toString();
     *     let string = wordArray.toString(CryptoJS.enc.Utf8);
     */

    toString: function(encoder) {
      return (encoder || Hex).stringify(this)
    },

    /**
     * Concatenates a word array to this word array.
     *
     * @param {Array} wordArray WordArray -- The word array to append.
     *
     * @returns {Array} WordArray -- This word array.
     *
     * @example
     *
     *     wordArray1.concat(wordArray2);
     */

    concat: function(wordArray) {
      // Shortcuts
      let thisWords = this.words
      let thatWords = wordArray.words
      let thisSigBytes = this.sigBytes
      let thatSigBytes = wordArray.sigBytes

      // Clamp excess bits
      this.clamp()

      // Concat
      if (thisSigBytes % 4) {
        // Copy one byte at a time
        for (let i = 0; i < thatSigBytes; i++) {
          let thatByte = (thatWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
          thisWords[(thisSigBytes + i) >>> 2] |= thatByte << (24 - ((thisSigBytes + i) % 4) * 8)
        }
      } else if (thatWords.length > 0xffff) {
        // Copy one word at a time
        for (let i = 0; i < thatSigBytes; i += 4) {
          thisWords[(thisSigBytes + i) >>> 2] = thatWords[i >>> 2]
        }
      } else {
        // Copy all words at once
        thisWords.push.apply(thisWords, thatWords)
      }
      this.sigBytes += thatSigBytes

      // Chainable
      return this
    },

    /**
     * Removes insignificant bits.
     *
     * @example
     *
     *     wordArray.clamp();
     */

    clamp: function() {
      // Shortcuts
      let words = this.words
      let sigBytes = this.sigBytes

      // Clamp
      words[sigBytes >>> 2] &= 0xffffffff << (32 - (sigBytes % 4) * 8)
      words.length = Math.ceil(sigBytes / 4)
    },

    /**
     * Creates a copy of this word array.
     *
     * @returns {Array} WordArray -- The clone.
     *
     * @example
     *
     *     let clone = wordArray.clone();
     */

    clone: function() {
      let clone = Base.clone.call(this)
      clone.words = this.words.slice(0)

      return clone
    },

    /**
     * Creates a word array filled with random bytes.
     *
     * @param {number} nBytes The number of random bytes to generate.
     *
     * @returns {Array} WordArray -- The random word array.
     *
     * @static
     *
     * @example
     *
     *     let wordArray = CryptoJS.lib.WordArray.random(16);
     */

    random: function(nBytes) {
      let words = []

      for (let i = 0; i < nBytes; i += 4) {
        words.push((Math.random() * 0x100000000) | 0)
      }

      return new WordArray.Init(words, nBytes)
    }
  }))

  /**
   * Encoder namespace.
   */
  let CEnc = (C.enc = {})

  /**
   * Hex encoding strategy.
   */
  let Hex = (CEnc.Hex = {
    /**
     * Converts a word array to a hex string.
     *
     * @param {Array} wordArray WordArray -- The word array.
     *
     * @returns {string} The hex string.
     *
     * @static
     *
     * @example
     *
     *     let hexString = CryptoJS.enc.Hex.stringify(wordArray);
     */

    stringify: function(wordArray) {
      // Shortcuts
      let words = wordArray.words
      let sigBytes = wordArray.sigBytes

      // Convert
      let hexChars = []
      for (let i = 0; i < sigBytes; i++) {
        let bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
        hexChars.push((bite >>> 4).toString(16))
        hexChars.push((bite & 0x0f).toString(16))
      }

      return hexChars.join('')
    },

    /**
     * Converts a hex string to a word array.
     *
     * @param {string} hexStr The hex string.
     *
     * @returns {Array} WordArray -- The word array.
     *
     * @static
     *
     * @example
     *
     *     let wordArray = CryptoJS.enc.Hex.parse(hexString);
     */

    parse: function(hexStr) {
      // Shortcut
      let hexStrLength = hexStr.length

      // Convert
      let words = []
      for (let i = 0; i < hexStrLength; i += 2) {
        words[i >>> 3] |= parseInt(hexStr.substr(i, 2), 16) << (24 - (i % 8) * 4)
      }

      return new WordArray.Init(words, hexStrLength / 2)
    }
  })

  /**
   * Latin1 encoding strategy.
   */
  let Latin1 = (CEnc.Latin1 = {
    /**
     * Converts a word array to a Latin1 string.
     *
     * @param {Array} wordArray WordArray -- The word array.
     *
     * @returns {string} The Latin1 string.
     *
     * @static
     *
     * @example
     *
     *     let latin1String = CryptoJS.enc.Latin1.stringify(wordArray);
     */

    stringify: function(wordArray) {
      // Shortcuts
      let words = wordArray.words
      let sigBytes = wordArray.sigBytes

      // Convert
      let latin1Chars = []
      for (let i = 0; i < sigBytes; i++) {
        let bite = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
        latin1Chars.push(String.fromCharCode(bite))
      }

      return latin1Chars.join('')
    },

    /**
     * Converts a Latin1 string to a word array.
     *
     * @param {string} latin1Str The Latin1 string.
     *
     * @returns {Array} WordArray -- The word array.
     *
     * @static
     *
     * @example
     *
     *     let wordArray = CryptoJS.enc.Latin1.parse(latin1String);
     */

    parse: function(latin1Str) {
      // Shortcut
      let latin1StrLength = latin1Str.length

      // Convert
      let words = []
      for (let i = 0; i < latin1StrLength; i++) {
        words[i >>> 2] |= (latin1Str.charCodeAt(i) & 0xff) << (24 - (i % 4) * 8)
      }

      return new WordArray.Init(words, latin1StrLength)
    }
  })

  /**
   * UTF-8 encoding strategy.
   */
  let Utf8 = (CEnc.Utf8 = {
    /**
     * Converts a word array to a UTF-8 string.
     *
     * @param {Array} wordArray WordArray -- The word array.
     *
     * @returns {string} The UTF-8 string.
     *
     * @static
     *
     * @example
     *
     *     let utf8String = CryptoJS.enc.Utf8.stringify(wordArray);
     */

    stringify: function(wordArray) {
      try {
        return decodeURIComponent(escape(Latin1.stringify(wordArray)))
      } catch (e) {
        throw new Error('Malformed UTF-8 data')
      }
    },

    /**
     * Converts a UTF-8 string to a word array.
     *
     * @param {string} utf8Str The UTF-8 string.
     *
     * @returns {Array} WordArray -- The word array.
     *
     * @static
     *
     * @example
     *
     *     let wordArray = CryptoJS.enc.Utf8.parse(utf8String);
     */

    parse: function(utf8Str) {
      return Latin1.parse(unescape(encodeURIComponent(utf8Str)))
    }
  })

  /**
   * Abstract buffered block algorithm template.
   *
   * The property blockSize must be implemented in a concrete subtype.
   *
   * @property {number} _minBufferSize The number of blocks that should be kept unprocessed in the buffer. Default: 0
   */
  let BufferedBlockAlgorithm = (CLib.BufferedBlockAlgorithm = Base.extend({
    /**
     * Resets this block algorithm's data buffer to its Initial state.
     *
     * @example
     *
     *     bufferedBlockAlgorithm.reset();
     */

    reset: function() {
      // Initial values
      this._data = new WordArray.Init()
      this._nDataBytes = 0
    },

    /**
     * Adds new data to this block algorithm's buffer.
     *
     * @param {Array|string} data WordArray|string -- The data to append. Strings are converted to a WordArray using UTF-8.
     *
     * @example
     *
     *     bufferedBlockAlgorithm._append('data');
     *     bufferedBlockAlgorithm._append(wordArray);
     */

    _append: function(data) {
      // Convert string to WordArray, else assume WordArray already
      if (typeof data === 'string') {
        data = Utf8.parse(data)
      }

      // Append
      this._data.concat(data)
      this._nDataBytes += data.sigBytes
    },

    /**
     * Processes available data blocks.
     *
     * This method invokes _doProcessBlock(offset), which must be implemented by a concrete subtype.
     *
     * @param {boolean} doFlush Whether all blocks and partial blocks should be processed.
     *
     * @returns {Array} WordArray - The processed data.
     *
     * @example
     *
     *     let processedData = bufferedBlockAlgorithm._process();
     *     let processedData = bufferedBlockAlgorithm._process(!!'flush');
     */

    _process: function(doFlush) {
      // Shortcuts
      let data = this._data
      let dataWords = data.words
      let dataSigBytes = data.sigBytes
      let blockSize = this.blockSize
      let blockSizeBytes = blockSize * 4
      let processedWords

      // Count blocks ready
      let nBlocksReady = dataSigBytes / blockSizeBytes
      if (doFlush) {
        // Round up to include partial blocks
        nBlocksReady = Math.ceil(nBlocksReady)
      } else {
        // Round down to include only full blocks,
        // less the number of blocks that must remain in the buffer
        nBlocksReady = Math.max((nBlocksReady | 0) - this._minBufferSize, 0)
      }

      // Count words ready
      let nWordsReady = nBlocksReady * blockSize

      // Count bytes ready
      let nBytesReady = Math.min(nWordsReady * 4, dataSigBytes)

      // Process blocks
      if (nWordsReady) {
        for (let offset = 0; offset < nWordsReady; offset += blockSize) {
          // Perform concrete-algorithm logic
          this._doProcessBlock(dataWords, offset)
        }

        // Remove processed words
        processedWords = dataWords.splice(0, nWordsReady)
        data.sigBytes -= nBytesReady
      }

      // Return processed words
      return new WordArray.Init(processedWords, nBytesReady)
    },

    /**
     * Creates a copy of this object.
     *
     * @returns {Object} The clone.
     *
     * @example
     *
     *     let clone = bufferedBlockAlgorithm.clone();
     */

    clone: function() {
      let clone = Base.clone.call(this)
      clone._data = this._data.clone()

      return clone
    },

    _minBufferSize: 0
  }))

  /**
   * Abstract hasher template.
   *
   * @property {number} blockSize The number of 32-bit words this hasher operates on. Default: 16 (512 bits)
   */
  // let Hasher = CLib.Hasher = BufferedBlockAlgorithm.extend({
  CLib.Hasher = BufferedBlockAlgorithm.extend({
    /**
     * Configuration options.
     */
    cfg: Base.extend(),

    /**
     * Initializes a newly created hasher.
     *
     * @param {Object} cfg (Optional) The configuration options to use for this hash computation.
     *
     * @example
     *
     *     let hasher = CryptoJS.algo.SHA256.create();
     */

    Init: function(cfg) {
      // Apply config defaults
      this.cfg = this.cfg.extend(cfg)

      // Set Initial values
      this.reset()
    },

    /**
     * Resets this hasher to its Initial state.
     *
     * @example
     *
     *     hasher.reset();
     */

    reset: function() {
      // Reset data buffer
      BufferedBlockAlgorithm.reset.call(this)

      // Perform concrete-hasher logic
      this._doReset()
    },

    /**
     * Updates this hasher with a message.
     *
     * @param {Array|string} messageUpdate WordArray|string -- The message to append.
     *
     * @returns {string} Hasher -- This hasher.
     *
     * @example
     *
     *     hasher.update('message');
     *     hasher.update(wordArray);
     */

    update: function(messageUpdate) {
      // Append
      this._append(messageUpdate)

      // Update the hash
      this._process()

      // Chainable
      return this
    },

    /**
     * Finalizes the hash computation.
     * Note that the finalize operation is effectively a destructive, read-once operation.
     *
     * @param {Array|string} messageUpdate WordArray|string -- (Optional) A final message update.
     *
     * @returns {Array} WordArray -- The hash.
     *
     * @example
     *
     *     let hash = hasher.finalize();
     *     let hash = hasher.finalize('message');
     *     let hash = hasher.finalize(wordArray);
     */

    finalize: function(messageUpdate) {
      // Final message update
      if (messageUpdate) {
        this._append(messageUpdate)
      }

      // Perform concrete-hasher logic
      let hash = this._doFinalize()

      return hash
    },

    blockSize: 512 / 32,

    /**
     * Creates a shortcut function to a hasher's object interface.
     *
     * @param {string} hasher Hasher -- The hasher to create a helper for.
     *
     * @returns {Function} The shortcut function.
     *
     * @static
     *
     * @example
     *
     *     let SHA256 = CryptoJS.lib.Hasher._createHelper(CryptoJS.algo.SHA256);
     */

    _createHelper: function(hasher) {
      return function(message, cfg) {
        return new hasher.Init(cfg).finalize(message)
      }
    },

    /**
     * Creates a shortcut function to the HMAC's object interface.
     *
     * @param {string} hasher Hasher -- The hasher to use in this HMAC helper.
     *
     * @returns {Function} The shortcut function.
     *
     * @static
     *
     * @example
     *
     *     let HmacSHA256 = CryptoJS.lib.Hasher._createHmacHelper(CryptoJS.algo.SHA256);
     */

    _createHmacHelper: function(hasher) {
      return function(message, key) {
        return new CAlgo.HMAC.Init(hasher, key).finalize(message)
      }
    }
  })

  /**
   * Algorithm namespace.
   */
  let CAlgo = (C.algo = {})

  return C
})(Math)

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
;(function() {
  // Shortcuts
  let C = CryptoJS
  let CLib = C.lib
  let WordArray = CLib.WordArray
  let CEnc = C.enc

  /**
   * Base64 encoding strategy.
   */
  // let Base64 = CEnc.Base64 = {
  CEnc.Base64 = {
    /**
     * Converts a word array to a Base64 string.
     *
     * @param {Array} wordArray WordArray -- The word array.
     *
     * @returns {string} The Base64 string.
     *
     * @static
     *
     * @example
     *
     *     let base64String = CryptoJS.enc.Base64.stringify(wordArray);
     */

    stringify: function(wordArray) {
      // Shortcuts
      let words = wordArray.words
      let sigBytes = wordArray.sigBytes
      let map = this._map

      // Clamp excess bits
      wordArray.clamp()

      // Convert
      let base64Chars = []
      for (let i = 0; i < sigBytes; i += 3) {
        let byte1 = (words[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff
        let byte2 = (words[(i + 1) >>> 2] >>> (24 - ((i + 1) % 4) * 8)) & 0xff
        let byte3 = (words[(i + 2) >>> 2] >>> (24 - ((i + 2) % 4) * 8)) & 0xff

        let triplet = (byte1 << 16) | (byte2 << 8) | byte3

        for (let j = 0; j < 4 && i + j * 0.75 < sigBytes; j++) {
          base64Chars.push(map.charAt((triplet >>> (6 * (3 - j))) & 0x3f))
        }
      }

      // Add padding
      let paddingChar = map.charAt(64)
      if (paddingChar) {
        while (base64Chars.length % 4) {
          base64Chars.push(paddingChar)
        }
      }

      return base64Chars.join('')
    },

    /**
     * Converts a Base64 string to a word array.
     *
     * @param {string} base64Str The Base64 string.
     *
     * @returns {Array} WordArray -- The word array.
     *
     * @static
     *
     * @example
     *
     *     let wordArray = CryptoJS.enc.Base64.parse(base64String);
     */

    parse: function(base64Str) {
      // Shortcuts
      let base64StrLength = base64Str.length
      let map = this._map

      // Ignore padding
      let paddingChar = map.charAt(64)
      if (paddingChar) {
        let paddingIndex = base64Str.indexOf(paddingChar)
        if (paddingIndex !== -1) {
          base64StrLength = paddingIndex
        }
      }

      // Convert
      let words = []
      let nBytes = 0
      for (let i = 0; i < base64StrLength; i++) {
        if (i % 4) {
          let bits1 = map.indexOf(base64Str.charAt(i - 1)) << ((i % 4) * 2)
          let bits2 = map.indexOf(base64Str.charAt(i)) >>> (6 - (i % 4) * 2)
          words[nBytes >>> 2] |= (bits1 | bits2) << (24 - (nBytes % 4) * 8)
          nBytes++
        }
      }

      return WordArray.create(words, nBytes)
    },

    _map: 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  }
})()

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
;(function(Math) {
  // Shortcuts
  let C = CryptoJS
  let CLib = C.lib
  let WordArray = CLib.WordArray
  let Hasher = CLib.Hasher
  let CAlgo = C.algo

  // Constants table
  let T = []

  // Compute constants
  ;(function() {
    for (let i = 0; i < 64; i++) {
      T[i] = (Math.abs(Math.sin(i + 1)) * 0x100000000) | 0
    }
  })()

  /**
   * MD5 hash algorithm.
   */
  let MD5 = (CAlgo.MD5 = Hasher.extend({
    _doReset: function() {
      this._hash = new WordArray.Init([0x67452301, 0xefcdab89, 0x98badcfe, 0x10325476])
    },

    _doProcessBlock: function(M, offset) {
      // Swap endian
      for (let i = 0; i < 16; i++) {
        // Shortcuts
        let offsetI = offset + i
        let MOffsetI = M[offsetI]

        M[offsetI] =
          (((MOffsetI << 8) | (MOffsetI >>> 24)) & 0x00ff00ff) |
          (((MOffsetI << 24) | (MOffsetI >>> 8)) & 0xff00ff00)
      }

      // Shortcuts
      let H = this._hash.words

      let MOffset0 = M[offset + 0]
      let MOffset1 = M[offset + 1]
      let MOffset2 = M[offset + 2]
      let MOffset3 = M[offset + 3]
      let MOffset4 = M[offset + 4]
      let MOffset5 = M[offset + 5]
      let MOffset6 = M[offset + 6]
      let MOffset7 = M[offset + 7]
      let MOffset8 = M[offset + 8]
      let MOffset9 = M[offset + 9]
      let MOffset10 = M[offset + 10]
      let MOffset11 = M[offset + 11]
      let MOffset12 = M[offset + 12]
      let MOffset13 = M[offset + 13]
      let MOffset14 = M[offset + 14]
      let MOffset15 = M[offset + 15]

      // Working letialbes
      let a = H[0]
      let b = H[1]
      let c = H[2]
      let d = H[3]

      // Computation
      a = FF(a, b, c, d, MOffset0, 7, T[0])
      d = FF(d, a, b, c, MOffset1, 12, T[1])
      c = FF(c, d, a, b, MOffset2, 17, T[2])
      b = FF(b, c, d, a, MOffset3, 22, T[3])
      a = FF(a, b, c, d, MOffset4, 7, T[4])
      d = FF(d, a, b, c, MOffset5, 12, T[5])
      c = FF(c, d, a, b, MOffset6, 17, T[6])
      b = FF(b, c, d, a, MOffset7, 22, T[7])
      a = FF(a, b, c, d, MOffset8, 7, T[8])
      d = FF(d, a, b, c, MOffset9, 12, T[9])
      c = FF(c, d, a, b, MOffset10, 17, T[10])
      b = FF(b, c, d, a, MOffset11, 22, T[11])
      a = FF(a, b, c, d, MOffset12, 7, T[12])
      d = FF(d, a, b, c, MOffset13, 12, T[13])
      c = FF(c, d, a, b, MOffset14, 17, T[14])
      b = FF(b, c, d, a, MOffset15, 22, T[15])

      a = GG(a, b, c, d, MOffset1, 5, T[16])
      d = GG(d, a, b, c, MOffset6, 9, T[17])
      c = GG(c, d, a, b, MOffset11, 14, T[18])
      b = GG(b, c, d, a, MOffset0, 20, T[19])
      a = GG(a, b, c, d, MOffset5, 5, T[20])
      d = GG(d, a, b, c, MOffset10, 9, T[21])
      c = GG(c, d, a, b, MOffset15, 14, T[22])
      b = GG(b, c, d, a, MOffset4, 20, T[23])
      a = GG(a, b, c, d, MOffset9, 5, T[24])
      d = GG(d, a, b, c, MOffset14, 9, T[25])
      c = GG(c, d, a, b, MOffset3, 14, T[26])
      b = GG(b, c, d, a, MOffset8, 20, T[27])
      a = GG(a, b, c, d, MOffset13, 5, T[28])
      d = GG(d, a, b, c, MOffset2, 9, T[29])
      c = GG(c, d, a, b, MOffset7, 14, T[30])
      b = GG(b, c, d, a, MOffset12, 20, T[31])

      a = HH(a, b, c, d, MOffset5, 4, T[32])
      d = HH(d, a, b, c, MOffset8, 11, T[33])
      c = HH(c, d, a, b, MOffset11, 16, T[34])
      b = HH(b, c, d, a, MOffset14, 23, T[35])
      a = HH(a, b, c, d, MOffset1, 4, T[36])
      d = HH(d, a, b, c, MOffset4, 11, T[37])
      c = HH(c, d, a, b, MOffset7, 16, T[38])
      b = HH(b, c, d, a, MOffset10, 23, T[39])
      a = HH(a, b, c, d, MOffset13, 4, T[40])
      d = HH(d, a, b, c, MOffset0, 11, T[41])
      c = HH(c, d, a, b, MOffset3, 16, T[42])
      b = HH(b, c, d, a, MOffset6, 23, T[43])
      a = HH(a, b, c, d, MOffset9, 4, T[44])
      d = HH(d, a, b, c, MOffset12, 11, T[45])
      c = HH(c, d, a, b, MOffset15, 16, T[46])
      b = HH(b, c, d, a, MOffset2, 23, T[47])

      a = II(a, b, c, d, MOffset0, 6, T[48])
      d = II(d, a, b, c, MOffset7, 10, T[49])
      c = II(c, d, a, b, MOffset14, 15, T[50])
      b = II(b, c, d, a, MOffset5, 21, T[51])
      a = II(a, b, c, d, MOffset12, 6, T[52])
      d = II(d, a, b, c, MOffset3, 10, T[53])
      c = II(c, d, a, b, MOffset10, 15, T[54])
      b = II(b, c, d, a, MOffset1, 21, T[55])
      a = II(a, b, c, d, MOffset8, 6, T[56])
      d = II(d, a, b, c, MOffset15, 10, T[57])
      c = II(c, d, a, b, MOffset6, 15, T[58])
      b = II(b, c, d, a, MOffset13, 21, T[59])
      a = II(a, b, c, d, MOffset4, 6, T[60])
      d = II(d, a, b, c, MOffset11, 10, T[61])
      c = II(c, d, a, b, MOffset2, 15, T[62])
      b = II(b, c, d, a, MOffset9, 21, T[63])

      // Intermediate hash value
      H[0] = (H[0] + a) | 0
      H[1] = (H[1] + b) | 0
      H[2] = (H[2] + c) | 0
      H[3] = (H[3] + d) | 0
    },

    _doFinalize: function() {
      // Shortcuts
      let data = this._data
      let dataWords = data.words

      let nBitsTotal = this._nDataBytes * 8
      let nBitsLeft = data.sigBytes * 8

      // Add padding
      dataWords[nBitsLeft >>> 5] |= 0x80 << (24 - (nBitsLeft % 32))

      let nBitsTotalH = Math.floor(nBitsTotal / 0x100000000)
      let nBitsTotalL = nBitsTotal
      dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 15] =
        (((nBitsTotalH << 8) | (nBitsTotalH >>> 24)) & 0x00ff00ff) |
        (((nBitsTotalH << 24) | (nBitsTotalH >>> 8)) & 0xff00ff00)
      dataWords[(((nBitsLeft + 64) >>> 9) << 4) + 14] =
        (((nBitsTotalL << 8) | (nBitsTotalL >>> 24)) & 0x00ff00ff) |
        (((nBitsTotalL << 24) | (nBitsTotalL >>> 8)) & 0xff00ff00)

      data.sigBytes = (dataWords.length + 1) * 4

      // Hash final blocks
      this._process()

      // Shortcuts
      let hash = this._hash
      let H = hash.words

      // Swap endian
      for (let i = 0; i < 4; i++) {
        // Shortcut
        let HI = H[i]

        H[i] = (((HI << 8) | (HI >>> 24)) & 0x00ff00ff) | (((HI << 24) | (HI >>> 8)) & 0xff00ff00)
      }

      // Return final computed hash
      return hash
    },

    clone: function() {
      let clone = Hasher.clone.call(this)
      clone._hash = this._hash.clone()

      return clone
    }
  }))

  function FF(a, b, c, d, x, s, t) {
    let n = a + ((b & c) | (~b & d)) + x + t
    return ((n << s) | (n >>> (32 - s))) + b
  }

  function GG(a, b, c, d, x, s, t) {
    let n = a + ((b & d) | (c & ~d)) + x + t
    return ((n << s) | (n >>> (32 - s))) + b
  }

  function HH(a, b, c, d, x, s, t) {
    let n = a + (b ^ c ^ d) + x + t
    return ((n << s) | (n >>> (32 - s))) + b
  }

  function II(a, b, c, d, x, s, t) {
    let n = a + (c ^ (b | ~d)) + x + t
    return ((n << s) | (n >>> (32 - s))) + b
  }

  /**
   * Shortcut function to the hasher's object interface.
   *
   * @param {WordArray|string} message The message to hash.
   *
   * @returns {WordArray} The hash.
   *
   * @static
   *
   * @example
   *
   *     let hash = CryptoJS.MD5('message');
   *     let hash = CryptoJS.MD5(wordArray);
   */
  C.MD5 = Hasher._createHelper(MD5)

  /**
   * Shortcut function to the HMAC's object interface.
   *
   * @param {WordArray|string} message The message to hash.
   * @param {WordArray|string} key The secret key.
   *
   * @returns {WordArray} The HMAC.
   *
   * @static
   *
   * @example
   *
   *     let hmac = CryptoJS.HmacMD5(message, key);
   */
  C.HmacMD5 = Hasher._createHmacHelper(MD5)
})(Math)

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
;(function() {
  // Shortcuts
  let C = CryptoJS
  let CLib = C.lib
  let Base = CLib.Base
  let WordArray = CLib.WordArray
  let CAlgo = C.algo
  let MD5 = CAlgo.MD5

  /**
   * This key derivation function is meant to conform with EVP_BytesToKey.
   * www.openssl.org/docs/crypto/EVP_BytesToKey.html
   */
  let EvpKDF = (CAlgo.EvpKDF = Base.extend({
    /**
     * Configuration options.
     *
     * @property {number} keySize The key size in words to generate. Default: 4 (128 bits)
     * @property {Hasher} hasher The hash algorithm to use. Default: MD5
     * @property {number} iterations The number of iterations to perform. Default: 1
     */
    cfg: Base.extend({
      keySize: 128 / 32,
      hasher: MD5,
      iterations: 1
    }),

    /**
     * Initializes a newly created key derivation function.
     *
     * @param {Object} cfg (Optional) The configuration options to use for the derivation.
     *
     * @example
     *
     *     let kdf = CryptoJS.algo.EvpKDF.create();
     *     let kdf = CryptoJS.algo.EvpKDF.create({ keySize: 8 });
     *     let kdf = CryptoJS.algo.EvpKDF.create({ keySize: 8, iterations: 1000 });
     */

    Init: function(cfg) {
      this.cfg = this.cfg.extend(cfg)
    },

    /**
     * Derives a key from a password.
     *
     * @param {Array|string} password WordArray|string -- The password.
     * @param {Array|string} salt WordArray|string -- A salt.
     *
     * @returns {Array} WordArray -- The derived key.
     *
     * @example
     *
     *     let key = kdf.compute(password, salt);
     */

    compute: function(password, salt) {
      // Shortcut
      let cfg = this.cfg

      // Init hasher
      let hasher = cfg.hasher.create()

      // Initial values
      let derivedKey = WordArray.create()

      // Shortcuts
      let derivedKeyWords = derivedKey.words
      let keySize = cfg.keySize
      let iterations = cfg.iterations

      // Generate key
      while (derivedKeyWords.length < keySize) {
        let block = hasher.update(password).finalize(salt)

        if (block) {
          hasher.update(block)
        }

        hasher.reset()

        // Iterations
        for (let i = 1; i < iterations; i++) {
          block = hasher.finalize(block)
          hasher.reset()
        }

        derivedKey.concat(block)
      }
      derivedKey.sigBytes = keySize * 4

      return derivedKey
    }
  }))

  /**
   * Derives a key from a password.
   *
   * @param {Array|string} password WordArray|string -- The password.
   * @param {Array|string} salt WordArray|string -- A salt.
   * @param {Object} cfg (Optional) The configuration options to use for this computation.
   *
   * @returns {Array} WordArray -- The derived key.
   *
   * @static
   *
   * @example
   *
   *     let key = CryptoJS.EvpKDF(password, salt);
   *     let key = CryptoJS.EvpKDF(password, salt, { keySize: 8 });
   *     let key = CryptoJS.EvpKDF(password, salt, { keySize: 8, iterations: 1000 });
   */

  C.EvpKDF = function(password, salt, cfg) {
    return EvpKDF.create(cfg).compute(password, salt)
  }
})()

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
/**
 * Cipher core components.
 */
CryptoJS.lib.Cipher ||
  (function() {
    // Shortcuts
    let C = CryptoJS
    let CLib = C.lib
    let Base = CLib.Base
    let WordArray = CLib.WordArray
    let BufferedBlockAlgorithm = CLib.BufferedBlockAlgorithm
    let CEnc = C.enc
    // let Utf8 = CEnc.Utf8
    let Base64 = CEnc.Base64
    let CAlgo = C.algo
    let EvpKDF = CAlgo.EvpKDF

    /**
     * Abstract base cipher template.
     *
     * @property {number} keySize This cipher's key size. Default: 4 (128 bits)
     * @property {number} ivSize This cipher's IV size. Default: 4 (128 bits)
     * @property {number} _ENC_XFORM_MODE A constant representing encryption mode.
     * @property {number} _DEC_XFORM_MODE A constant representing decryption mode.
     */
    let Cipher = (CLib.Cipher = BufferedBlockAlgorithm.extend({
      /**
       * Configuration options.
       *
       * @property {WordArray} iv The IV to use for this operation.
       */
      cfg: Base.extend(),

      /**
       * Creates this cipher in encryption mode.
       *
       * @param {Array} key WordArray -- The key.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {string} Cipher -- A cipher instance.
       *
       * @static
       *
       * @example
       *
       *     let cipher = CryptoJS.algo.AES.createEncryptor(keyWordArray, { iv: ivWordArray });
       */

      createEncryptor: function(key, cfg) {
        return this.create(this._ENC_XFORM_MODE, key, cfg)
      },

      /**
       * Creates this cipher in decryption mode.
       *
       * @param {Array} key WordArray -- The key.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {string} Cipher -- A cipher instance.
       *
       * @static
       *
       * @example
       *
       *     let cipher = CryptoJS.algo.AES.createDecryptor(keyWordArray, { iv: ivWordArray });
       */

      createDecryptor: function(key, cfg) {
        return this.create(this._DEC_XFORM_MODE, key, cfg)
      },

      /**
       * Initializes a newly created cipher.
       *
       * @param {number} xformMode Either the encryption or decryption transormation mode constant.
       * @param {Array} key WordArray -- The key.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @example
       *
       *     let cipher = CryptoJS.algo.AES.create(CryptoJS.algo.AES._ENC_XFORM_MODE, keyWordArray, { iv: ivWordArray });
       */

      Init: function(xformMode, key, cfg) {
        // Apply config defaults
        this.cfg = this.cfg.extend(cfg)

        // Store transform mode and key
        this._xformMode = xformMode
        this._key = key

        // Set Initial values
        this.reset()
      },

      /**
       * Resets this cipher to its Initial state.
       *
       * @example
       *
       *     cipher.reset();
       */

      reset: function() {
        // Reset data buffer
        BufferedBlockAlgorithm.reset.call(this)

        // Perform concrete-cipher logic
        this._doReset()
      },

      /**
       * Adds data to be encrypted or decrypted.
       *
       * @param {Array|string} dataUpdate WordArray|string -- The data to encrypt or decrypt.
       *
       * @returns {Array} WordArray -- The data after processing.
       *
       * @example
       *
       *     let encrypted = cipher.process('data');
       *     let encrypted = cipher.process(wordArray);
       */

      process: function(dataUpdate) {
        // Append
        this._append(dataUpdate)

        // Process available blocks
        return this._process()
      },

      /**
       * Finalizes the encryption or decryption process.
       * Note that the finalize operation is effectively a destructive, read-once operation.
       *
       * @param {Array|string} dataUpdate WordArray|string -- The final data to encrypt or decrypt.
       *
       * @returns {Array} WordArray -- The data after final processing.
       *
       * @example
       *
       *     let encrypted = cipher.finalize();
       *     let encrypted = cipher.finalize('data');
       *     let encrypted = cipher.finalize(wordArray);
       */

      finalize: function(dataUpdate) {
        // Final data update
        if (dataUpdate) {
          this._append(dataUpdate)
        }

        // Perform concrete-cipher logic
        let finalProcessedData = this._doFinalize()

        return finalProcessedData
      },

      keySize: 128 / 32,

      ivSize: 128 / 32,

      _ENC_XFORM_MODE: 1,

      _DEC_XFORM_MODE: 2,

      /**
       * Creates shortcut functions to a cipher's object interface.
       *
       * @param {Cipher} cipher The cipher to create a helper for.
       *
       * @returns {Object} An object with encrypt and decrypt shortcut functions.
       *
       * @static
       *
       * @example
       *
       *     let AES = CryptoJS.lib.Cipher._createHelper(CryptoJS.algo.AES);
       */
      _createHelper: (function() {
        function selectCipherStrategy(key) {
          if (typeof key === 'string') {
            return PasswordBasedCipher
          } else {
            return SerializableCipher
          }
        }

        return function(cipher) {
          return {
            encrypt: function(message, key, cfg) {
              return selectCipherStrategy(key).encrypt(cipher, message, key, cfg)
            },

            decrypt: function(ciphertext, key, cfg) {
              return selectCipherStrategy(key).decrypt(cipher, ciphertext, key, cfg)
            }
          }
        }
      })()
    }))

    /**
     * Abstract base stream cipher template.
     *
     * @property {number} blockSize The number of 32-bit words this cipher operates on. Default: 1 (32 bits)
     */
    // let StreamCipher = CLib.StreamCipher = Cipher.extend({
    CLib.StreamCipher = Cipher.extend({
      _doFinalize: function() {
        // Process partial blocks
        let finalProcessedBlocks = this._process(!!'flush')

        return finalProcessedBlocks
      },

      blockSize: 1
    })

    /**
     * Mode namespace.
     */
    let CMode = (C.mode = {})

    /**
     * Abstract base block cipher mode template.
     */
    let BlockCipherMode = (CLib.BlockCipherMode = Base.extend({
      /**
       * Creates this mode for encryption.
       *
       * @param {string} cipher Cipher -- A block cipher instance.
       * @param {Array} iv The IV words.
       *
       * @static
       *
       * @example
       *
       *     let mode = CryptoJS.mode.CBC.createEncryptor(cipher, iv.words);
       */

      createEncryptor: function(cipher, iv) {
        return this.Encryptor.create(cipher, iv)
      },

      /**
       * Creates this mode for decryption.
       *
       * @param {string} cipher Cipher -- A block cipher instance.
       * @param {Array} iv The IV words.
       *
       * @static
       *
       * @example
       *
       *     let mode = CryptoJS.mode.CBC.createDecryptor(cipher, iv.words);
       */

      createDecryptor: function(cipher, iv) {
        return this.Decryptor.create(cipher, iv)
      },

      /**
       * Initializes a newly created mode.
       *
       * @param {string} cipher Cipher -- A block cipher instance.
       * @param {Array} iv The IV words.
       *
       * @example
       *
       *     let mode = CryptoJS.mode.CBC.Encryptor.create(cipher, iv.words);
       */

      Init: function(cipher, iv) {
        this._cipher = cipher
        this.Iv = iv
      }
    }))

    /**
     * Cipher Block Chaining mode.
     */
    let CBC = (CMode.CBC = (function() {
      /**
       * Abstract base CBC mode.
       */
      let CBC = BlockCipherMode.extend()

      /**
       * CBC encryptor.
       */
      CBC.Encryptor = CBC.extend({
        /**
         * Processes the data block at offset.
         *
         * @param {Array} words The data words to operate on.
         * @param {number} offset The offset where the block starts.
         *
         * @example
         *
         *     mode.processBlock(data.words, offset);
         */

        processBlock: function(words, offset) {
          // Shortcuts
          let cipher = this._cipher
          let blockSize = cipher.blockSize

          // XOR and encrypt
          xorBlock.call(this, words, offset, blockSize)
          cipher.encryptBlock(words, offset)

          // Remember this block to use with next block
          this._prevBlock = words.slice(offset, offset + blockSize)
        }
      })

      /**
       * CBC decryptor.
       */
      CBC.Decryptor = CBC.extend({
        /**
         * Processes the data block at offset.
         *
         * @param {Array} words The data words to operate on.
         * @param {number} offset The offset where the block starts.
         *
         * @example
         *
         *     mode.processBlock(data.words, offset);
         */

        processBlock: function(words, offset) {
          // Shortcuts
          let cipher = this._cipher
          let blockSize = cipher.blockSize

          // Remember this block to use with next block
          let thisBlock = words.slice(offset, offset + blockSize)

          // Decrypt and XOR
          cipher.decryptBlock(words, offset)
          xorBlock.call(this, words, offset, blockSize)

          // This block becomes the previous block
          this._prevBlock = thisBlock
        }
      })

      function xorBlock(words, offset, blockSize) {
        // Shortcut
        let iv = this.Iv
        let block

        // Choose mixing block
        if (iv) {
          block = iv

          // Remove IV for subsequent blocks
          this.Iv = undefined
        } else {
          block = this._prevBlock
        }

        // XOR blocks
        for (let i = 0; i < blockSize; i++) {
          words[offset + i] ^= block[i]
        }
      }

      return CBC
    })())

    /**
     * Padding namespace.
     */
    let CPad = (C.pad = {})

    /**
     * PKCS #5/7 padding strategy.
     */
    let Pkcs7 = (CPad.Pkcs7 = {
      /**
       * Pads data using the algorithm defined in PKCS #5/7.
       *
       * @param {Array} data WordArray -- The data to pad.
       * @param {number} blockSize The multiple that the data should be padded to.
       *
       * @static
       *
       * @example
       *
       *     CryptoJS.pad.Pkcs7.pad(wordArray, 4);
       */

      pad: function(data, blockSize) {
        // Shortcut
        let blockSizeBytes = blockSize * 4

        // Count padding bytes
        let nPaddingBytes = blockSizeBytes - (data.sigBytes % blockSizeBytes)

        // Create padding word
        let paddingWord =
          (nPaddingBytes << 24) | (nPaddingBytes << 16) | (nPaddingBytes << 8) | nPaddingBytes

        // Create padding
        let paddingWords = []
        for (let i = 0; i < nPaddingBytes; i += 4) {
          paddingWords.push(paddingWord)
        }
        let padding = WordArray.create(paddingWords, nPaddingBytes)

        // Add padding
        data.concat(padding)
      },

      /**
       * Unpads data that had been padded using the algorithm defined in PKCS #5/7.
       *
       * @param {Array} data  WordArray -- The data to unpad.
       *
       * @static
       *
       * @example
       *
       *     CryptoJS.pad.Pkcs7.unpad(wordArray);
       */

      unpad: function(data) {
        // Get number of padding bytes from last byte
        let nPaddingBytes = data.words[(data.sigBytes - 1) >>> 2] & 0xff

        // Remove padding
        data.sigBytes -= nPaddingBytes
      }
    })

    /**
     * Abstract base block cipher template.
     *
     * @property {number} blockSize The number of 32-bit words this cipher operates on. Default: 4 (128 bits)
     */
    // let BlockCipher = CLib.BlockCipher = Cipher.extend({
    CLib.BlockCipher = Cipher.extend({
      /**
       * Configuration options.
       *
       * @property {Mode} mode The block mode to use. Default: CBC
       * @property {Padding} padding The padding strategy to use. Default: Pkcs7
       */
      cfg: Cipher.cfg.extend({
        mode: CBC,
        padding: Pkcs7
      }),

      reset: function() {
        // Reset cipher
        Cipher.reset.call(this)

        // Shortcuts
        let cfg = this.cfg
        let iv = cfg.iv
        let mode = cfg.mode
        let modeCreator

        // Reset block mode
        if (this._xformMode === this._ENC_XFORM_MODE) {
          modeCreator = mode.createEncryptor
        } /* if (this._xformMode == this._DEC_XFORM_MODE) */ else {
          modeCreator = mode.createDecryptor

          // Keep at least one block in the buffer for unpadding
          this._minBufferSize = 1
        }
        this._mode = modeCreator.call(mode, this, iv && iv.words)
      },

      _doProcessBlock: function(words, offset) {
        this._mode.processBlock(words, offset)
      },

      _doFinalize: function() {
        // Shortcut
        let padding = this.cfg.padding
        let finalProcessedBlocks

        // Finalize
        if (this._xformMode === this._ENC_XFORM_MODE) {
          // Pad data
          padding.pad(this._data, this.blockSize)

          // Process final blocks
          finalProcessedBlocks = this._process(!!'flush')
        } /* if (this._xformMode == this._DEC_XFORM_MODE) */ else {
          // Process final blocks
          finalProcessedBlocks = this._process(!!'flush')

          // Unpad data
          padding.unpad(finalProcessedBlocks)
        }

        return finalProcessedBlocks
      },

      blockSize: 128 / 32
    })

    /**
     * A collection of cipher parameters.
     *
     * @property {WordArray} ciphertext The raw ciphertext.
     * @property {WordArray} key The key to this ciphertext.
     * @property {WordArray} iv The IV used in the ciphering operation.
     * @property {WordArray} salt The salt used with a key derivation function.
     * @property {Cipher} algorithm The cipher algorithm.
     * @property {Mode} mode The block mode used in the ciphering operation.
     * @property {Padding} padding The padding scheme used in the ciphering operation.
     * @property {number} blockSize The block size of the cipher.
     * @property {Format} formatter The default formatting strategy to convert this cipher params object to a string.
     */

    let CipherParams = (CLib.CipherParams = Base.extend({
      /**
       * Initializes a newly created cipher params object.
       *
       * @param {Object} cipherParams An object with any of the possible cipher parameters.
       *
       * @example
       *
       *     let cipherParams = CryptoJS.lib.CipherParams.create({
       *         ciphertext: ciphertextWordArray,
       *         key: keyWordArray,
       *         iv: ivWordArray,
       *         salt: saltWordArray,
       *         algorithm: CryptoJS.algo.AES,
       *         mode: CryptoJS.mode.CBC,
       *         padding: CryptoJS.pad.PKCS7,
       *         blockSize: 4,
       *         formatter: CryptoJS.format.OpenSSL
       *     });
       */

      Init: function(cipherParams) {
        this.mixIn(cipherParams)
      },

      /**
       * Converts this cipher params object to a string.
       *
       * @param {string} formatter Format -- (Optional) The formatting strategy to use.
       *
       * @returns {string} The stringified cipher params.
       *
       * @throws Error If neither the formatter nor the default formatter is set.
       *
       * @example
       *
       *     let string = cipherParams + '';
       *     let string = cipherParams.toString();
       *     let string = cipherParams.toString(CryptoJS.format.OpenSSL);
       */

      toString: function(formatter) {
        return (formatter || this.formatter).stringify(this)
      }
    }))

    /**
     * Format namespace.
     */
    let CFormat = (C.format = {})

    /**
     * OpenSSL formatting strategy.
     */
    let OpenSSLFormatter = (CFormat.OpenSSL = {
      /**
       * Converts a cipher params object to an OpenSSL-compatible string.
       *
       * @param {string} cipherParams CipherParams -- The cipher params object.
       *
       * @returns {string} The OpenSSL-compatible string.
       *
       * @static
       *
       * @example
       *
       *     let openSSLString = CryptoJS.format.OpenSSL.stringify(cipherParams);
       */

      stringify: function(cipherParams) {
        // Shortcuts
        let ciphertext = cipherParams.ciphertext
        let salt = cipherParams.salt
        let wordArray

        // Format
        if (salt) {
          wordArray = WordArray.create([0x53616c74, 0x65645f5f])
            .concat(salt)
            .concat(ciphertext)
        } else {
          wordArray = ciphertext
        }

        return wordArray.toString(Base64)
      },

      /**
       * Converts an OpenSSL-compatible string to a cipher params object.
       *
       * @param {string} openSSLStr The OpenSSL-compatible string.
       *
       * @returns {string} CipherParams -- The cipher params object.
       *
       * @static
       *
       * @example
       *
       *     let cipherParams = CryptoJS.format.OpenSSL.parse(openSSLString);
       */

      parse: function(openSSLStr) {
        // Parse base64
        let ciphertext = Base64.parse(openSSLStr)

        // Shortcut
        let ciphertextWords = ciphertext.words

        let salt

        // Test for salt
        if (ciphertextWords[0] === 0x53616c74 && ciphertextWords[1] === 0x65645f5f) {
          // Extract salt
          salt = WordArray.create(ciphertextWords.slice(2, 4))

          // Remove salt from ciphertext
          ciphertextWords.splice(0, 4)
          ciphertext.sigBytes -= 16
        }

        return CipherParams.create({ ciphertext: ciphertext, salt: salt })
      }
    })

    /**
     * A cipher wrapper that returns ciphertext as a serializable cipher params object.
     */
    let SerializableCipher = (CLib.SerializableCipher = Base.extend({
      /**
       * Configuration options.
       *
       * @property {Formatter} format The formatting strategy to convert cipher param objects to and from a string. Default: OpenSSL
       */
      cfg: Base.extend({
        format: OpenSSLFormatter
      }),

      /**
       * Encrypts a message.
       *
       * @param {string} cipher Cipher -- The cipher algorithm to use.
       * @param {Array|string} message WordArray|string -- The message to encrypt.
       * @param {Array} key WordArray -- The key.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {string} CipherParams -- A cipher params object.
       *
       * @static
       *
       * @example
       *
       *     let ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key);
       *     let ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key, { iv: iv });
       *     let ciphertextParams = CryptoJS.lib.SerializableCipher.encrypt(CryptoJS.algo.AES, message, key, { iv: iv, format: CryptoJS.format.OpenSSL });
       */

      encrypt: function(cipher, message, key, cfg) {
        // Apply config defaults
        cfg = this.cfg.extend(cfg)

        // Encrypt
        let encryptor = cipher.createEncryptor(key, cfg)
        let ciphertext = encryptor.finalize(message)

        // Shortcut
        let cipherCfg = encryptor.cfg

        // Create and return serializable cipher params
        return CipherParams.create({
          ciphertext: ciphertext,
          key: key,
          iv: cipherCfg.iv,
          algorithm: cipher,
          mode: cipherCfg.mode,
          padding: cipherCfg.padding,
          blockSize: cipher.blockSize,
          formatter: cfg.format
        })
      },

      /**
       * Decrypts serialized ciphertext.
       *
       * @param {string} cipher Cipher -- The cipher algorithm to use.
       * @param {Object|string} ciphertext CipherParams|string -- The ciphertext to decrypt.
       * @param {Array} key WordArray -- The key.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {Array} WordArray -- The plaintext.
       *
       * @static
       *
       * @example
       *
       *     let plaintext = CryptoJS.lib.SerializableCipher.decrypt(CryptoJS.algo.AES, formattedCiphertext, key, { iv: iv, format: CryptoJS.format.OpenSSL });
       *     let plaintext = CryptoJS.lib.SerializableCipher.decrypt(CryptoJS.algo.AES, ciphertextParams, key, { iv: iv, format: CryptoJS.format.OpenSSL });
       */

      decrypt: function(cipher, ciphertext, key, cfg) {
        // Apply config defaults
        cfg = this.cfg.extend(cfg)

        // Convert string to CipherParams
        ciphertext = this._parse(ciphertext, cfg.format)

        // Decrypt
        let plaintext = cipher.createDecryptor(key, cfg).finalize(ciphertext.ciphertext)

        return plaintext
      },

      /**
       * Converts serialized ciphertext to CipherParams,
       * else assumed CipherParams already and returns ciphertext unchanged.
       *
       * @param {Object|string} ciphertext CipherParams|string -- The ciphertext.
       * @param {string} format Formatter -- The formatting strategy to use to parse serialized ciphertext.
       *
       * @returns {string} CipherParams -- The unserialized ciphertext.
       *
       * @static
       *
       * @example
       *
       *     let ciphertextParams = CryptoJS.lib.SerializableCipher._parse(ciphertextStringOrParams, format);
       */

      _parse: function(ciphertext, format) {
        if (typeof ciphertext === 'string') {
          return format.parse(ciphertext, this)
        } else {
          return ciphertext
        }
      }
    }))

    /**
     * Key derivation function namespace.
     */
    let CKdf = (C.kdf = {})

    /**
     * OpenSSL key derivation function.
     */
    let OpenSSLKdf = (CKdf.OpenSSL = {
      /**
       * Derives a key and IV from a password.
       *
       * @param {string} password The password to derive from.
       * @param {number} keySize The size in words of the key to generate.
       * @param {number} ivSize The size in words of the IV to generate.
       * @param {Array|string} salt WordArray|string -- (Optional) A 64-bit salt to use. If omitted, a salt will be generated randomly.
       *
       * @returns {string} CipherParams -- A cipher params object with the key, IV, and salt.
       *
       * @static
       *
       * @example
       *
       *     let derivedParams = CryptoJS.kdf.OpenSSL.execute('Password', 256/32, 128/32);
       *     let derivedParams = CryptoJS.kdf.OpenSSL.execute('Password', 256/32, 128/32, 'saltsalt');
       */

      execute: function(password, keySize, ivSize, salt) {
        // Generate random salt
        if (!salt) {
          salt = WordArray.random(64 / 8)
        }

        // Derive key and IV
        let key = EvpKDF.create({ keySize: keySize + ivSize }).compute(password, salt)

        // Separate key and IV
        let iv = WordArray.create(key.words.slice(keySize), ivSize * 4)
        key.sigBytes = keySize * 4

        // Return params
        return CipherParams.create({ key: key, iv: iv, salt: salt })
      }
    })

    /**
     * A serializable cipher wrapper that derives the key from a password,
     * and returns ciphertext as a serializable cipher params object.
     */
    let PasswordBasedCipher = (CLib.PasswordBasedCipher = SerializableCipher.extend({
      /**
       * Configuration options.
       *
       * @property {KDF} kdf The key derivation function to use to generate a key and IV from a password. Default: OpenSSL
       */
      cfg: SerializableCipher.cfg.extend({
        kdf: OpenSSLKdf
      }),

      /**
       * Encrypts a message using a password.
       *
       * @param {string} cipher Cipher -- The cipher algorithm to use.
       * @param {Array|string} message WordArray|string -- The message to encrypt.
       * @param {string} password The password.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {Object} CipherParams -- A cipher params object.
       *
       * @static
       *
       * @example
       *
       *     let ciphertextParams = CryptoJS.lib.PasswordBasedCipher.encrypt(CryptoJS.algo.AES, message, 'password');
       *     let ciphertextParams = CryptoJS.lib.PasswordBasedCipher.encrypt(CryptoJS.algo.AES, message, 'password', { format: CryptoJS.format.OpenSSL });
       */

      encrypt: function(cipher, message, password, cfg) {
        // Apply config defaults
        cfg = this.cfg.extend(cfg)

        // Derive key and other params
        let derivedParams = cfg.kdf.execute(password, cipher.keySize, cipher.ivSize)

        // Add IV to config
        cfg.iv = derivedParams.iv

        // Encrypt
        let ciphertext = SerializableCipher.encrypt.call(
          this,
          cipher,
          message,
          derivedParams.key,
          cfg
        )

        // Mix in derived params
        ciphertext.mixIn(derivedParams)

        return ciphertext
      },

      /**
       * Decrypts serialized ciphertext using a password.
       *
       * @param {string} cipher Cipher -- The cipher algorithm to use.
       * @param {Object|string} ciphertext CipherParams|string -- The ciphertext to decrypt.
       * @param {string} password The password.
       * @param {Object} cfg (Optional) The configuration options to use for this operation.
       *
       * @returns {Array} WordArray -- The plaintext.
       *
       * @static
       *
       * @example
       *
       *     let plaintext = CryptoJS.lib.PasswordBasedCipher.decrypt(CryptoJS.algo.AES, formattedCiphertext, 'password', { format: CryptoJS.format.OpenSSL });
       *     let plaintext = CryptoJS.lib.PasswordBasedCipher.decrypt(CryptoJS.algo.AES, ciphertextParams, 'password', { format: CryptoJS.format.OpenSSL });
       */

      decrypt: function(cipher, ciphertext, password, cfg) {
        // Apply config defaults
        cfg = this.cfg.extend(cfg)

        // Convert string to CipherParams
        ciphertext = this._parse(ciphertext, cfg.format)

        // Derive key and other params
        let derivedParams = cfg.kdf.execute(
          password,
          cipher.keySize,
          cipher.ivSize,
          ciphertext.salt
        )

        // Add IV to config
        cfg.iv = derivedParams.iv

        // Decrypt
        let plaintext = SerializableCipher.decrypt.call(
          this,
          cipher,
          ciphertext,
          derivedParams.key,
          cfg
        )

        return plaintext
      }
    }))
  })()

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
;(function() {
  // Shortcuts
  let C = CryptoJS
  let CLib = C.lib
  let BlockCipher = CLib.BlockCipher
  let CAlgo = C.algo

  // Lookup tables
  let SBOX = []
  let INV_SBOX = []
  let SUB_MIX_0 = []
  let SUB_MIX_1 = []
  let SUB_MIX_2 = []
  let SUB_MIX_3 = []
  let INV_SUB_MIX_0 = []
  let INV_SUB_MIX_1 = []
  let INV_SUB_MIX_2 = []
  let INV_SUB_MIX_3 = []

  // Compute lookup tables
  ;(function() {
    // Compute double table
    let d = []
    for (let i = 0; i < 256; i++) {
      if (i < 128) {
        d[i] = i << 1
      } else {
        d[i] = (i << 1) ^ 0x11b
      }
    }

    // Walk GF(2^8)
    let x = 0
    let xi = 0
    for (let i = 0; i < 256; i++) {
      // Compute sbox
      let sx = xi ^ (xi << 1) ^ (xi << 2) ^ (xi << 3) ^ (xi << 4)
      sx = (sx >>> 8) ^ (sx & 0xff) ^ 0x63
      SBOX[x] = sx
      INV_SBOX[sx] = x

      // Compute multiplication
      let x2 = d[x]
      let x4 = d[x2]
      let x8 = d[x4]
      let t

      // Compute sub bytes, mix columns tables
      t = (d[sx] * 0x101) ^ (sx * 0x1010100)
      SUB_MIX_0[x] = (t << 24) | (t >>> 8)
      SUB_MIX_1[x] = (t << 16) | (t >>> 16)
      SUB_MIX_2[x] = (t << 8) | (t >>> 24)
      SUB_MIX_3[x] = t

      // Compute inv sub bytes, inv mix columns tables
      t = (x8 * 0x1010101) ^ (x4 * 0x10001) ^ (x2 * 0x101) ^ (x * 0x1010100)
      INV_SUB_MIX_0[sx] = (t << 24) | (t >>> 8)
      INV_SUB_MIX_1[sx] = (t << 16) | (t >>> 16)
      INV_SUB_MIX_2[sx] = (t << 8) | (t >>> 24)
      INV_SUB_MIX_3[sx] = t

      // Compute next counter
      if (!x) {
        x = xi = 1
      } else {
        x = x2 ^ d[d[d[x8 ^ x2]]]
        xi ^= d[d[xi]]
      }
    }
  })()

  // Precomputed Rcon lookup
  let RCON = [0x00, 0x01, 0x02, 0x04, 0x08, 0x10, 0x20, 0x40, 0x80, 0x1b, 0x36]

  /**
   * AES block cipher algorithm.
   */
  let AES = (CAlgo.AES = BlockCipher.extend({
    _doReset: function() {
      // Shortcuts
      let key = this._key
      let keyWords = key.words
      let keySize = key.sigBytes / 4

      // Compute number of rounds
      let nRounds = (this._nRounds = keySize + 6)

      // Compute number of key schedule rows
      let ksRows = (nRounds + 1) * 4

      // Compute key schedule
      let keySchedule = (this._keySchedule = [])
      for (let ksRow = 0; ksRow < ksRows; ksRow++) {
        if (ksRow < keySize) {
          keySchedule[ksRow] = keyWords[ksRow]
        } else {
          let t = keySchedule[ksRow - 1]

          if (!(ksRow % keySize)) {
            // Rot word
            t = (t << 8) | (t >>> 24)

            // Sub word
            t =
              (SBOX[t >>> 24] << 24) |
              (SBOX[(t >>> 16) & 0xff] << 16) |
              (SBOX[(t >>> 8) & 0xff] << 8) |
              SBOX[t & 0xff]

            // Mix Rcon
            t ^= RCON[(ksRow / keySize) | 0] << 24
          } else if (keySize > 6 && ksRow % keySize === 4) {
            // Sub word
            t =
              (SBOX[t >>> 24] << 24) |
              (SBOX[(t >>> 16) & 0xff] << 16) |
              (SBOX[(t >>> 8) & 0xff] << 8) |
              SBOX[t & 0xff]
          }

          keySchedule[ksRow] = keySchedule[ksRow - keySize] ^ t
        }
      }

      // Compute inv key schedule
      let invKeySchedule = (this.InvKeySchedule = [])
      for (let invKsRow = 0; invKsRow < ksRows; invKsRow++) {
        let ksRow = ksRows - invKsRow
        let t

        if (invKsRow % 4) {
          t = keySchedule[ksRow]
        } else {
          t = keySchedule[ksRow - 4]
        }

        if (invKsRow < 4 || ksRow <= 4) {
          invKeySchedule[invKsRow] = t
        } else {
          invKeySchedule[invKsRow] =
            INV_SUB_MIX_0[SBOX[t >>> 24]] ^
            INV_SUB_MIX_1[SBOX[(t >>> 16) & 0xff]] ^
            INV_SUB_MIX_2[SBOX[(t >>> 8) & 0xff]] ^
            INV_SUB_MIX_3[SBOX[t & 0xff]]
        }
      }
    },

    encryptBlock: function(M, offset) {
      this._doCryptBlock(
        M,
        offset,
        this._keySchedule,
        SUB_MIX_0,
        SUB_MIX_1,
        SUB_MIX_2,
        SUB_MIX_3,
        SBOX
      )
    },

    decryptBlock: function(M, offset) {
      let t

      // Swap 2nd and 4th rows
      t = M[offset + 1]
      M[offset + 1] = M[offset + 3]
      M[offset + 3] = t

      this._doCryptBlock(
        M,
        offset,
        this.InvKeySchedule,
        INV_SUB_MIX_0,
        INV_SUB_MIX_1,
        INV_SUB_MIX_2,
        INV_SUB_MIX_3,
        INV_SBOX
      )

      // Inv swap 2nd and 4th rows
      t = M[offset + 1]
      M[offset + 1] = M[offset + 3]
      M[offset + 3] = t
    },

    _doCryptBlock: function(
      M,
      offset,
      keySchedule,
      SUB_MIX_0,
      SUB_MIX_1,
      SUB_MIX_2,
      SUB_MIX_3,
      SBOX
    ) {
      // Shortcut
      let nRounds = this._nRounds

      // Get input, add round key
      let s0 = M[offset] ^ keySchedule[0]
      let s1 = M[offset + 1] ^ keySchedule[1]
      let s2 = M[offset + 2] ^ keySchedule[2]
      let s3 = M[offset + 3] ^ keySchedule[3]

      // Key schedule row counter
      let ksRow = 4

      // Rounds
      for (let round = 1; round < nRounds; round++) {
        // Shift rows, sub bytes, mix columns, add round key
        let t0 =
          SUB_MIX_0[s0 >>> 24] ^
          SUB_MIX_1[(s1 >>> 16) & 0xff] ^
          SUB_MIX_2[(s2 >>> 8) & 0xff] ^
          SUB_MIX_3[s3 & 0xff] ^
          keySchedule[ksRow++]
        let t1 =
          SUB_MIX_0[s1 >>> 24] ^
          SUB_MIX_1[(s2 >>> 16) & 0xff] ^
          SUB_MIX_2[(s3 >>> 8) & 0xff] ^
          SUB_MIX_3[s0 & 0xff] ^
          keySchedule[ksRow++]
        let t2 =
          SUB_MIX_0[s2 >>> 24] ^
          SUB_MIX_1[(s3 >>> 16) & 0xff] ^
          SUB_MIX_2[(s0 >>> 8) & 0xff] ^
          SUB_MIX_3[s1 & 0xff] ^
          keySchedule[ksRow++]
        let t3 =
          SUB_MIX_0[s3 >>> 24] ^
          SUB_MIX_1[(s0 >>> 16) & 0xff] ^
          SUB_MIX_2[(s1 >>> 8) & 0xff] ^
          SUB_MIX_3[s2 & 0xff] ^
          keySchedule[ksRow++]

        // Update state
        s0 = t0
        s1 = t1
        s2 = t2
        s3 = t3
      }

      // Shift rows, sub bytes, add round key
      let t0 =
        ((SBOX[s0 >>> 24] << 24) |
          (SBOX[(s1 >>> 16) & 0xff] << 16) |
          (SBOX[(s2 >>> 8) & 0xff] << 8) |
          SBOX[s3 & 0xff]) ^
        keySchedule[ksRow++]
      let t1 =
        ((SBOX[s1 >>> 24] << 24) |
          (SBOX[(s2 >>> 16) & 0xff] << 16) |
          (SBOX[(s3 >>> 8) & 0xff] << 8) |
          SBOX[s0 & 0xff]) ^
        keySchedule[ksRow++]
      let t2 =
        ((SBOX[s2 >>> 24] << 24) |
          (SBOX[(s3 >>> 16) & 0xff] << 16) |
          (SBOX[(s0 >>> 8) & 0xff] << 8) |
          SBOX[s1 & 0xff]) ^
        keySchedule[ksRow++]
      let t3 =
        ((SBOX[s3 >>> 24] << 24) |
          (SBOX[(s0 >>> 16) & 0xff] << 16) |
          (SBOX[(s1 >>> 8) & 0xff] << 8) |
          SBOX[s2 & 0xff]) ^
        keySchedule[ksRow++]

      // Set output
      M[offset] = t0
      M[offset + 1] = t1
      M[offset + 2] = t2
      M[offset + 3] = t3
    },

    keySize: 256 / 32
  }))

  /**
   * Shortcut functions to the cipher's object interface.
   *
   * @example
   *
   *     let ciphertext = CryptoJS.AES.encrypt(message, key, cfg);
   *     let plaintext  = CryptoJS.AES.decrypt(ciphertext, key, cfg);
   */
  C.AES = BlockCipher._createHelper(AES)
})()

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
/**
 * Cipher Feedback block mode.
 */
CryptoJS.mode.CFB = (function() {
  let CFB = CryptoJS.lib.BlockCipherMode.extend()

  CFB.Encryptor = CFB.extend({
    processBlock: function(words, offset) {
      // Shortcuts
      let cipher = this._cipher
      let blockSize = cipher.blockSize

      generateKeystreamAndEncrypt.call(this, words, offset, blockSize, cipher)

      // Remember this block to use with next block
      this._prevBlock = words.slice(offset, offset + blockSize)
    }
  })

  CFB.Decryptor = CFB.extend({
    processBlock: function(words, offset) {
      // Shortcuts
      let cipher = this._cipher
      let blockSize = cipher.blockSize

      // Remember this block to use with next block
      let thisBlock = words.slice(offset, offset + blockSize)

      generateKeystreamAndEncrypt.call(this, words, offset, blockSize, cipher)

      // This block becomes the previous block
      this._prevBlock = thisBlock
    }
  })

  function generateKeystreamAndEncrypt(words, offset, blockSize, cipher) {
    // Shortcut
    let iv = this.Iv
    let keystream

    // Generate keystream
    if (iv) {
      keystream = iv.slice(0)

      // Remove IV for subsequent blocks
      this.Iv = undefined
    } else {
      keystream = this._prevBlock
    }
    cipher.encryptBlock(keystream, 0)

    // Encrypt
    for (let i = 0; i < blockSize; i++) {
      words[offset + i] ^= keystream[i]
    }
  }

  return CFB
})()

/*
CryptoJS v3.1.2
code.google.com/p/crypto-js
(c) 2009-2013 by Jeff Mott. All rights reserved.
code.google.com/p/crypto-js/wiki/License
*/
/**
 * Zero padding strategy.
 */
CryptoJS.pad.ZeroPadding = {
  pad: function(data, blockSize) {
    // Shortcut
    let blockSizeBytes = blockSize * 4

    // Pad
    data.clamp()
    data.sigBytes += blockSizeBytes - (data.sigBytes % blockSizeBytes || blockSizeBytes)
  },

  unpad: function(data) {
    // Shortcut
    let dataWords = data.words

    // Unpad
    let i = data.sigBytes - 1
    while (!((dataWords[i >>> 2] >>> (24 - (i % 4) * 8)) & 0xff)) {
      i--
    }
    data.sigBytes = i + 1
  }
}

export { CryptoJS }
