/*
 * @Author: your name
 * @Date: 2022-02-21 17:32:25
 * @LastEditTime: 2022-08-31 11:19:16
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\runConcent.js
 */
import { run } from 'concent'
// import models from './routes/ContractApply/concent'
import models from './concent'

run(models)
