/*
 * @Author: your name
 * @Date: 2022-02-21 17:30:04
 * @LastEditTime: 2022-09-19 16:16:36
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\concent\counter\index.js
 */

import { getUserLimitList } from '../../services/api'

/**
 * @description 配置权限树(params:page)
 * @author yaoyihao
 * @export
 * @return {*}
 */
export function saveLimitsByPageId(pageId, current, action) {
  // setTimeout(() => {
  //   if (c.visible) {
  //     a.setState({ visible: false })
  //   }
  // }, 3000)
  // return { list: r, visible: true }
  const defalutMap = new Map(current.limitMap)
  console.log(pageId)

  if (!defalutMap.get(pageId)) {
    getUserLimitList(pageId).then((res) => {
      if (res) {
        defalutMap.set(window.location.pathname, res)
        defalutMap.set(pageId, res)
        action.setState({ limitMap: defalutMap })
        console.log(res, defalutMap)

        return { limitMap: defalutMap }
      }
    })
  }
}
