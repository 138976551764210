import { ctxState } from '../interface'
/*
 * @Author: your name
 * @Date: 2022-02-21 17:30:04
 * @LastEditTime: 2022-09-19 11:03:50
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\concent\counter\index.js
 */

const state: ctxState = {
  userInfo: null,
  tableFormList: [{ id: 0 }],
  traffics: [],
  accommodTypes: [],
  city: [],
  fileList: [],
  detailModalVisible: false,
  travelDetail: null,
  editInfo: null,
  loading: false,
  limitMap: new Map(),
  expenseList: [],
}
export default state
