import { createContext } from 'react'

/** 默认表格参数 */
export const defaultTableProps: any = {
  bordered: true,
  scroll: {
    x: 1440,
  },
}
/** 默认分页参数 */
export const defaultPaginationProps: any = {
  size: 'small',
  showQuickJumper: true,
  showSizeChanger: true,
}
/**
 * 表单折叠Context
 */
export const FormCollapseContext = createContext<any>({
  triggerCollapse() { },
  collapsed: false,
})
