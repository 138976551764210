/*
 * @Author: 姚一豪 yihao.yao@1hai.cn
 * @Date: 2022-09-19 15:57:10
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @LastEditTime: 2022-11-17 17:22:38
 * @FilePath: \oaCostcontrol\src\layouts\Routes.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { Spin } from 'antd'
import { useConcent } from 'concent'
import React, { Suspense, lazy, useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { ctxInterface } from '../concent/interface'
const Home = lazy(() => import('../routes/home')) // 首页

const Apply = lazy(() => import('../routes/Apply'))
const Query = lazy(() => import('../routes/Query'))
const TravelDetail = lazy(() => import('../routes/Query/DetailPage'))
const AgreeDetail = lazy(
  () => import('../routes/RecurringAgreement/DetailPage/index')
)
const TravelAudit = lazy(() => import('../routes/Query/AuditPage'))
const TravelList = lazy(() => import('../routes/TravelList'))
const ConventionApply = lazy(() => import('../routes/ConventionApply/index'))
const RecurringAgreement = lazy(
  () => import('../routes/RecurringAgreement/index')
)
const ApplyHost = lazy(() => import('../routes/ApplyHost'))
const CostManagement = lazy(() => import('../routes/CostManagement/index'))
const ApplyHostDetail = lazy(() => import('../routes/ApplyHost/detail'))
const BudgetAccountInfo = lazy(
  () => import('../routes/Budget/BudgetAccountInfo')
)
const BudgetPerformance = lazy(
  () => import('../routes/Budget/BudgetPerformance/index')
)
const DepartmentalBudgetManagement = lazy(
  () => import('../routes/Budget/DepartmentalBudgetManagement')
)
const BudgetPerformanceDetail = lazy(
  () => import('../routes/Budget/BudgetPerformance/details')
)
const BusinessBudgetControl = lazy(
  () => import('../routes/Budget/BusinessBudgetManagement')
)
const BudgetDetail = lazy(() => import('../routes/Budget/BudgetDetail'))
const AppendBudget = lazy(() => import('../routes/AppendBudget/index'))
const Routes = ({ privilegeTree }) => {
  const ctx: ctxInterface = useConcent('default')
  useEffect(() => {
    /* 自动匹配获取权限 */
    if (privilegeTree && privilegeTree?.length > 0) {
      privilegeTree?.map((item) => {
        if (
          item.levelType === 3 &&
          item.moduleUrl === window.location.pathname
        ) {
          console.log('save')
          ctx.mr.saveLimitsByPageId(item.moduleId)
        }
      })
    }
  }, [ctx.mr, privilegeTree])
  return (
    <Suspense
      fallback={
        <div className="suspense-box">
          {/* <Spin */}
          <Spin size="large" />
        </div>
      }
    >
      <Switch>
        <Route path="/" exact component={Home} />
        <Route path="/apply" exact component={Apply} />
        <Route path="/cost/query" exact component={Query} />
        <Route path="/travel/details" exact component={TravelDetail} />
        <Route
          path="/agreement/charge/detail/referNo"
          exact
          component={AgreeDetail}
        />
        {/* AgreeDetail */}

        <Route path="/audit" exact component={TravelAudit} />
        <Route path="/travel-list" exact component={TravelList} />
        <Route path="/cost/travel-m-query" exact component={Query} />
        <Route path="/convention-apply" exact component={ConventionApply} />
        <Route
          path="/cost/recurring-agreement"
          exact
          component={RecurringAgreement}
        />
        <Route path="/cost/entertain/apply" exact component={ApplyHost} />
        <Route path="/cost/cost-management" exact component={CostManagement} />
        <Route path="/entertain/detail" exact component={ApplyHostDetail} />
        <Route
          path="/budget/departmental-budget-management"
          exact
          component={DepartmentalBudgetManagement}
        />
        <Route
          path="/budget/budget-account-info"
          exact
          component={BudgetAccountInfo}
        />
        <Route
          path="/budget/budget-performance"
          exact
          component={BudgetPerformance}
        />
        <Route
          path="/budget-performance/detail"
          exact
          component={BudgetPerformanceDetail}
        />
        <Route
          path="/budget/business-budget-management"
          exact
          component={BusinessBudgetControl}
        />
        <Route path="/budget/detail" exact component={BudgetDetail} />
        <Route path="/append-budget" exact component={AppendBudget} />
        {/* CostManagement */}
        {/* TravelAudit */}
        {/* Query */}
        {/* Apply */}
        <Redirect to="/" />
      </Switch>
    </Suspense>
  )
}
export default Routes
