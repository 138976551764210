/*
 * @Author: your name
 * @Date: 2022-02-21 17:30:04
 * @LastEditTime: 2022-09-01 15:22:36
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\concent\counter\index.js
 */
import state from './state.ts'
import * as reducer from './reducer'
import * as computed from './computed'
import * as watch from './watch'
import init from './init'

export default {
  state,
  reducer,
  computed,
  watch,
  init
}
