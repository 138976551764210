/*
 * @Author: your name
 * @Date: 2022-02-21 17:29:26
 * @LastEditTime: 2022-08-31 11:19:49
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \zjOaAdmin\src\concent\index.js
 */
import counter from './counter'

export default {
  default: counter
}
