/*
 * @Author: 姚一豪 yihao.yao@1hai.cn
 * @Date: 2020-11-20 09:40:38
 * @LastEditors: 姚一豪 yihao.yao@1hai.cn
 * @LastEditTime: 2022-12-27 16:10:29
 * @FilePath: \oaContract\src\index.tsx
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
// import zhCN from 'antd/locale/zh_CN';
import * as serviceWorker from './serviceWorker'
// import '~antd/dist/antd.dark.less'; // 引入官方提供的暗色 less 样式入口文件
// import '~antd/dist/antd.compact.less'; // 引入官方提供的紧凑 less 样式入口文件
import App from './layouts/BasicLayout'
import './index.less'
import './styles/index.less'
import './runConcent'

import VConsole from 'vconsole'

import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

const env = process.env.BUILD_ENV

console.log(env)

// 非 testing prod 显示 vconsole
if (env !== 'testing' && env !== 'prod') {
  /* eslint-disable */
  new VConsole()
}
ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={zhCN}>
      <App />
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
